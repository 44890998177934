<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Body-->
    <div class="card-body  py-8 px-8 py-lg-20 px-lg-10">
      <div class="row">
        <div class="offset-xxl-2 col-xxl-8">
          <!-- begin::Profile Form-->
          <b-form class="form" v-on:submit.prevent="onSubmitProfile">
            <h4 class="mb-10 font-weight-bold text-dark">
              Benutzerkonto
            </h4>
            <ValidatedInput
              name="Email"
              v-model="userProfile.email"
              :v="$v.userProfile.email"
              errorMessage="Das ist ein Pflichtfeld und muss eine gültige Email Adresse sein."
              :is-read-only="true"
            />
            <div class="row">
              <div class="col-xl-6">
                <ValidatedInput
                  name="Vorname"
                  v-model="userProfile.first_name"
                  :v="$v.userProfile.first_name"
                  errorMessage="Das ist ein Pflichtfeld."
                />
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Nachname"
                  v-model="userProfile.last_name"
                  :v="$v.userProfile.last_name"
                  errorMessage="Das ist ein Pflichtfeld."
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <ValidatedInput
                  name="Position"
                  v-model="userProfile.function"
                  :v="$v.userProfile.function"
                  errorMessage="Das ist ein Pflichtfeld."
                  helpText="Geben Sie ihre Position im Unternehmen an."
                />
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Telefon"
                  v-model="userProfile.phone"
                  :v="$v.userProfile.phone"
                  errorMessage="Das ist ein Pflichtfeld"
                  :helpText="'Bsp: ' + phone_example"
                />
              </div>
            </div>
            <!--begin: Actions -->
            <div class="d-flex justify-content-between border-top pt-10 pb-10">
              <div class="mr-2"></div>
              <div>
                <button
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                  Speichern
                </button>
              </div>
            </div>
            <!--end: Actions -->
          </b-form>
          <!--end::Profile Form-->

          <div class="border-bottom mt-10 mb-10" />
          <!-- begin::Change Password Form-->
          <b-form class="form" v-on:submit.prevent="onChangePassword">
            <h4 class="mb-10 font-weight-bold text-dark">
              Passwort ändern
            </h4>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <label>Neues Passwort</label>
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    placeholder="Neues Passwort eingeben..."
                    v-model="$v.changePassword.new_password1.$model"
                    :state="validateChangePasswordState('new_password1')"
                    aria-describedby="input-live-feedback"
                  />
                  <password-meter
                    :password="$v.changePassword.new_password1.$model"
                    @score="onPasswordScore"
                  />
                  <b-form-invalid-feedback id="input--live-feedback">
                    {{ getPasswordError() }}
                  </b-form-invalid-feedback>
                  <b-form-text
                    >Das Passwort muss mindestens 9 Zeichen lang sein und
                    mindestens eine Zahl, einen Kleinbuchstaben und einen
                    Großbuchstaben enthalten.</b-form-text
                  >
                </div>
              </div>
              <div class="col-xl-6">
                <ValidatedInput
                  name="Passwort bestätigen"
                  type="password"
                  v-model="changePassword.new_password2"
                  :v="$v.changePassword.new_password2"
                  placeholder="Passwort bestätigen..."
                  errorMessage="Die Bestätigung muss mit ihrem Passwort übereinstimmen."
                />
              </div>
            </div>
            <!--begin: Actions -->
            <div class="d-flex justify-content-between border-top pt-10 pb-10">
              <div class="mr-2"></div>
              <div>
                <button
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                >
                  <i class="flaticon2-lock"></i>Passwort ändern
                </button>
              </div>
            </div>
            <!--end: Actions -->
          </b-form>
          <!--end::Change Password Form-->
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import UserService from "@/services/user.service";
// import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required, sameAs } from "vuelidate/lib/validators";
import ValidatedInput from "@/components/ValidatedInput";
import {
  LOAD_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_PASSWORD,
  RESET_CHANGE_PASSWORD
} from "@/store/profile.module";
import { phoneNumberValidationMixin } from "@/mixins/phoneNumberValidationMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { authFormMixin } from "@/mixins/authFormMixin";
import passwordMeter from "vue-simple-password-meter";

function phoneNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "phone", false);
}

function isPasswordValidValidation() {
  return this.isPasswordValid();
}

function passwordScoreValidation() {
  return this.passwordScoreValidation();
}

export default {
  components: { ValidatedInput, passwordMeter },
  mixins: [
    validationMixin,
    phoneNumberValidationMixin,
    alertMixin,
    authFormMixin
  ],
  name: "PersonalInformation",
  data() {
    return {
      userProfile: {},
      changePassword: {
        new_password1: "",
        new_password2: ""
      }
    };
  },
  validations: {
    userProfile: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      function: {},
      email: {
        required,
        email
      },
      phone: {
        phoneNumberValidation
      }
    },
    changePassword: {
      new_password1: {
        required,
        passwordScoreValidation,
        isPasswordValidValidation
      },
      new_password2: {
        required,
        sameAsPassword: sameAs("new_password1")
      }
    }
  },
  mounted() {
    this.loadUserProfile();
  },
  methods: {
    validateChangePasswordState(name) {
      const { $dirty, $error } = this.$v.changePassword[name];
      return $dirty ? !$error : null;
    },
    loadUserProfile() {
      this.$store.dispatch(LOAD_USER_PROFILE).then(response => {
        this.userProfile = response.data;
        UserService.saveUser(this.userProfile);
      });
    },
    onSubmitProfile() {
      this.$v.userProfile.$touch();
      if (this.$v.userProfile.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      this.$store
        .dispatch(UPDATE_USER_PROFILE, this.userProfile)
        .then(response => {
          this.userProfile = response.data;
          UserService.saveUser(this.userProfile);
          this.showChangesSavedAlert();
        });
    },
    onChangePassword() {
      // clear existing server errors
      this.$store.dispatch(RESET_CHANGE_PASSWORD);

      this.$v.changePassword.$touch();
      if (this.$v.changePassword.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      this.$store
        .dispatch(CHANGE_PASSWORD, this.changePassword)
        .then(() => {
          this.showChangesSavedAlert();
        })
        .catch(() => {
          this.$v.changePassword.$touch();
          // console.error(this.errors);
          this.showCheckProvidedDataAlert();
        });
    },
    getPasswordError() {
      if (!this.$v.changePassword.new_password1.passwordScoreValidation) {
        return this.passwordScoreError;
      }
      if (!this.$v.changePassword.new_password1.isPasswordValidValidation) {
        return this.invalidPasswordError;
      }
    },
    isPasswordValid() {
      if (this.errors === null) {
        return true;
      }
      if ("new_password2" in this.errors.response.data) {
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.Profile.errors
    })
  }
};
</script>
