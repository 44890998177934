// import ApiService from "@/services/api.service";

const PASSWORD_SCORE_ERROR =
  "Ihr Passwort entspricht nicht unseren Passwortregeln. Bitte versuchen Sie es erneut.";
const INVALID_PASSWORD_ERROR =
  "Ihr Passwort ist zu allgemein oder kann mit Ihren Benutzerangaben in Verbingung gebracht werden. Bitte versuchen Sie es erneut.";

export const authFormMixin = {
  data() {
    return {
      _passwordScore: 0,
      passwordScoreError: PASSWORD_SCORE_ERROR,
      invalidPasswordError: INVALID_PASSWORD_ERROR
    };
  },
  props: {},
  methods: {
    // async isEmailUniqueValidation(value) {
    //   if (value === "") {
    //     return true;
    //   }
    //   return await this._apiRequest("checkEmailExists", { email: value });
    // },
    // async isUsernameUniqueValidation(value) {
    //   if (value === "") {
    //     return true;
    //   }
    //   return await this._apiRequest("checkUserExists", { username: value });
    // },
    // async isValidPasswordValidation(value) {
    //   if (value === "") {
    //     return true;
    //   }
    //   return await this._apiRequest("validatePassword", { password1: value });
    // },
    // async _apiRequest(url, payload) {
    //   return await ApiService.post(url, payload)
    //     .then(() => {
    //       return true;
    //     })
    //     .catch(() => {
    //       return false;
    //     });
    // },
    onPasswordScore({ score }) {
      // console.log(score); // from 0 to 4
      // console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      this._passwordScore = score;
    },
    passwordScoreValidation() {
      return Boolean(this._passwordScore >= 3);
    }
  }
};
